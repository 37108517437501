.brain-tree-cc-fields {
  &__cc-icons {
    position: absolute;
    right: 1rem;
  }
}

#card-number,
#cvv,
#cardholder-name,
#expiration-date {
  &.braintree-hosted-fields-focused {
    border: 2px solid $c-primary;
  }
}
