h2 {
  color: $c-basicare-blue;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.185;
}

.container {
  @include media-breakpoint-only(xs) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.continue-btn {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    max-width: 408px;
  }
}

label,
.label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.modal.fade.show {
  padding-left: 0 !important;
}

.summary-table {
  &__item {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    &--md-75-25 {
      @include media-breakpoint-up(md) {
        grid-template-columns: 75% 25%;
      }
    }

    &-additional-info {
      font-size: 0.8rem;
    }
  }

  &__item-value {
    white-space: pre-line;
  }
}

.fw-500 {
  font-weight: 500;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes skeleton-loading-dark {
  0% {
    background-color: hsl(200, 20%, 30%);
  }
  100% {
    background-color: hsl(200, 20%, 45%);
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 0.25rem;
}

.skeleton.dark {
  animation: skeleton-loading-dark 1s linear infinite alternate;
}

.skeleton.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
}

.skeleton.skeleton-text__small {
  width: 30%;
}

.skeleton.skeleton-text__tall {
  height: 5rem;
}

.skeleton.skeleton-text__xltall {
  height: 10rem;
}

.skeleton.skeleton-image {
  height: 3rem;
}
