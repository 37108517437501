.form-control {
  border-color: $white;

  &::placeholder {
    color: rgba($c-basicare-neutral, 50%);
  }

  &.form-error {
    height: 38px;
    border-color: $c-danger;
  }
}

.label {
  &--required {
    &::after {
      content: "*";
      color: $c-danger;
      margin-left: 5px;
    }
  }
}
