.dependent-form {
  input[type="radio"]:disabled:checked + label {
    color: initial;

    &::before {
      color: $c-white;
      border-color: $c-primary;
      background-color: $c-primary !important;
    }
  }

  &__person-fields {
    input {
      border-color: #bec1da;
    }
  }
}
