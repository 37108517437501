.plans {
  margin: 36px 0;

  & h2 {
    margin-bottom: 18px;
  }
}

.plan {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: $white;
  border-top: 4px solid;
  text-align: center;
  cursor: pointer;
  padding: 1.5rem 0;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding: 0.75rem 1rem;
    margin-bottom: 1.25rem !important;
    flex-direction: row;
    justify-content: space-between;
  }

  &.btn {
    font-family: $font-family-rubik;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    font-size: 16px;
  }

  &.monthly {
    border-color: $c-basicare-secondary;

    &:hover {
      background: $c-basicare-secondary-2;
    }

    &.active {
      background: $c-basicare-secondary-3;
    }

    & .plan__price {
      color: $c-basicare-secondary;
      font-weight: 500;
    }

    & .plan__discount > span {
      background: $c-basicare-secondary;
    }
  }

  &.quarterly {
    border-color: $c-basicare-additional-blue;

    &:hover {
      background: $c-basicare-additional-blue-2;
    }

    &.active {
      background: $c-basicare-additional-blue-3;
    }

    & .plan__price {
      color: $c-basicare-additional-blue;
    }

    & .plan__discount > span {
      background: $c-basicare-additional-blue;
    }
  }

  &.annual {
    border-color: $c-basicare-additional-green;

    &:hover {
      background: $c-basicare-additional-green-2;
    }

    &.active {
      background: $c-basicare-additional-green-3;
    }

    & .plan__price {
      color: $c-basicare-additional-green;
    }

    & .plan__discount > span {
      background: $c-basicare-additional-green;
    }
  }

  &__interval {
    line-height: 1.5;
    font-weight: 400;
  }

  &__price {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.185;

    @include media-breakpoint-down(sm) {
      order: 4;
      flex: 0 0 96px;
      margin-left: 0.5rem;
    }
  }

  &__discount {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      position: initial;
      align-self: center;
      justify-content: end;
    }

    & span {
      font-size: 10px;
      font-weight: 400;
      display: block;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 0;
      width: 66px;
      text-align: center;
      color: $white;
      box-sizing: content-box;

      @include media-breakpoint-down(sm) {
        border-radius: 4px;
        padding: 1px 0;
      }
    }
  }
}
