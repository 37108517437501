@mixin responsive-padding {
  padding-left: 12px;
  padding-right: 12px;

  @include media-breakpoint-up(sm) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-only(xs) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}
