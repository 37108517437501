.summary-toggle-btn {
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $c-white;
  z-index: 50;

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__plan-price {
    font-weight: 500;
    font-size: 24px;
    color: $c-basicare-blue;

    &--discounted {
      font-size: 1.1rem;
    }

    &.monthly {
      color: $c-basicare-secondary;
    }

    &.quarterly {
      color: $c-basicare-additional-blue;
    }

    &.annual {
      color: $c-basicare-additional-green;
    }
  }

  &__details-btn {
    font-size: 16px;
    font-family: $font-family-rubik;
    font-weight: 500;
    color: $c-basicare-primary;

    &:hover {
      color: $c-basicare-primary;
    }
  }

  &__chevron {
    width: 12px;
    transform: rotate(180deg);
    margin-bottom: 2px;

    &.open {
      transform: rotate(0);
    }
  }
}
