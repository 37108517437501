.modal {
  &-footer {
    justify-content: center;

    @include media-breakpoint-down(md) {
      display: flex;
      gap: 1rem;
      button {
        flex-grow: 1;
      }
    }
  }
}
