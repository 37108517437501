.summary-drawer {
  overflow-x: none !important;
  overflow-y: none !important;

  &__dialog {
    width: 100vw;
    height: 100%;
    max-width: 100vw;
    margin: 0;
  }

  &__content {
    height: 100%;
    border: none;
    border-radius: 0;
  }

  .summary {
    box-shadow: none;
  }
}
.modal.fade:not(.show) .modal-dialog.summary-drawer__dialog {
  transform: translate(0, 1000px);
}
