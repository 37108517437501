.contact-us {
  padding: 0 0 100px 0;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: $c-basicare-primary-3;
    left: 0;
    right: 0;
    bottom: -10px;
    height: 30px;
  }
}
