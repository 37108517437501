.summary {
  .input-group-append {
    margin-left: 0;
  }

  box-shadow: $box-shadow;
  background-color: $c-white;

  @include responsive-padding;

  &--fullScreen {
    @include media-breakpoint-down(xs) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }

  &__header {
    border-bottom: 1px solid $c-basicare-primary-3;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: $c-white;

    &--sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &__primary-info {
    padding-top: 1.5rem;

    &--fullScreen {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        padding-top: 0.5rem;
        flex-wrap: wrap;

        .summary__line:first-child {
          display: none;
        }
      }
    }
  }

  &__dependents {
    margin-bottom: 1rem;
    @include media-breakpoint-up(xl) {
      border-top: 1px solid $c-basicare-primary-3;
    }
  }

  &__dependent {
    @include media-breakpoint-down(lg) {
      padding: 1rem 0;
    }
    border-top: 1px solid $c-basicare-primary-3;

    @include media-breakpoint-up(xl) {
      padding: 0 1rem;
      margin: 1rem 0;
      border-top: none;
      &:nth-child(odd) {
        padding-left: 0;
        border-right: 1px solid $c-basicare-primary-3;
      }
    }
  }

  &__dependent-row {
    row-gap: 1rem;
  }

  &__line-title {
    font-weight: 500;
    text-transform: capitalize;
  }

  &__line {
    margin-bottom: 1rem;
    max-width: 100%;
  }

  &__line-text {
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    text-wrap: wrap;
  }

  &__text--empty {
    padding-bottom: 18px;
    border-bottom: 1px solid $c-basicare-primary-3;
  }

  @mixin summary-total {
    background: $c-basicare-primary-3;
    margin-left: -12px;
    margin-right: -12px;

    @include media-breakpoint-up(sm) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    @include media-breakpoint-only(xs) {
      margin-left: -2.25rem;
      margin-right: -2.25rem;

      &--fullScreen {
        margin-left: -1rem;
        margin-right: -1rem;
      }
    }

    @include responsive-padding;
  }

  &__promo-code {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include summary-total;

    display: none;

    &--fullScreen {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(xs) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
        flex-direction: row;
        align-items: start;
        gap: 1rem;

        .summary__promo-code-input {
          min-width: 320px;
        }
      }
    }
  }

  &__selected-plan {
    display: flex;
    align-items: flex-start;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: sticky;
    bottom: 0;

    @include media-breakpoint-up(md) {
      align-items: center;
    }

    @include summary-total;

    &--fullScreen {
      @include media-breakpoint-down(xs) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &__plan-text {
    font-weight: 500;
  }

  &__plan-price {
    font-weight: 500;
    font-size: 24px;
    color: $c-basicare-blue;

    &--discounted {
      font-size: 1.1rem;
    }

    &.monthly {
      color: $c-basicare-secondary;
    }

    &.quarterly {
      color: $c-basicare-additional-blue;
    }

    &.annual {
      color: $c-basicare-additional-green;
    }
  }

  &__footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__footer-line {
    display: flex;
    align-items: center;

    a {
      text-decoration: underline;
    }
  }

  &__icon-circle {
    border-radius: 50%;
    background: $c-basicare-primary-3;
    min-height: 34px;
    min-width: 34px;
    position: relative;
    margin-right: 1rem;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
