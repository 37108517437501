.confirmation-message {
  &__heading {
    padding-bottom: 2rem;
    border-bottom: 1px solid $c-gray-350;
  }

  &__copy {
    font-weight: 500;
    font-size: 18px;
  }
}
