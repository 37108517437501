.wallet {
  &__pass-badge {
    display: inline-block;

    &--apple {
      width: 142px;

      &-mobile {
        margin-right: 1rem;

        @media (max-width: 375px) {
          margin-right: 0;
          margin-bottom: 1rem;
        }

        @media (min-width: 376px) and (max-width: 400px) {
          margin-left: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__qr-code {
    &--apple {
      width: 142px;
    }
  }
}
