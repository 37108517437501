body {
  font-family: $font-family-rubik;
  font-optical-sizing: auto;
  font-style: normal;
  color: $c-basicare-primary-2;
}

@include media-breakpoint-down(xs) {
  .container {
    &.container--fullwidth-mobile {
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      & .row--fullwidth-mobile {
        margin-left: 0;
        margin-right: 0;

        & .col--fullwidth-mobile {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.page-content {
  background: $c-basicare-primary-3;
  padding-top: 12px;
  padding-bottom: 12px;

  @include responsive-padding;

  @include media-breakpoint-only(xs) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }

  &__section {
    margin: 18px 0;

    h2 {
      margin-bottom: 18px;
    }
  }
}

.page-heading {
  margin-top: 36px;
  margin-bottom: 24px;

  @include media-breakpoint-down(md) {
    display: none;
  }

  h1 {
    margin: 0;
    font-size: 1.5rem;
    color: $c-basicare-primary;
    font-weight: 500;
  }
}

.main-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
