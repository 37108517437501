.dependent-card {
  &__controls {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    @include media-breakpoint-up(md) {
      bottom: calc(50% - 14px);
    }
    @include media-breakpoint-up(lg) {
      bottom: 1rem;
    }
    @include media-breakpoint-up(xl) {
      bottom: calc(50% - 14px);
    }
  }

  &__row {
    gap: 0;
    row-gap: 1rem;
    @include media-breakpoint-up(md) {
      gap: 1rem;
    }
  }

  .btn.btn-link:disabled {
    opacity: 0.3;
  }
}
