.add-dependents {
  padding-bottom: 100px;

  &__action-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      .async-button {
        width: 204px;
      }
    }
  }

  &__remove.btn-link {
    color: $c-basicare-secondary;
  }
}
