.btn {
  &-link {
    color: $c-basicare-orange-secondary;
    font-weight: 500;
    font-family: $font-family-raleway;
    font-size: 1rem;
    border: none;

    &:hover,
    &:focus {
      border: none;
      color: $c-basicare-orange-secondary;
    }
  }
}
