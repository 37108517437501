.promo-code-details {
  border-left: 4px solid $c-basicare-secondary;

  &--monthly {
    border-left-color: $c-basicare-secondary;
  }

  &--quarterly {
    border-left-color: $c-basicare-additional-blue;
  }

  &--annual {
    border-left-color: $c-basicare-additional-green;
  }
}
