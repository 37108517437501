.footer {
  background: $c-basicare-neutral;
  padding: 2rem 0 1.5rem;
  color: $white;

  & .container {
    @include media-breakpoint-only(xs) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  h6 {
    color: $white;
  }

  & strong {
    font-weight: 500;
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__hipaa {
    @include media-breakpoint-up(xl) {
      margin-left: 27px;
    }
  }

  &__basics-logo {
    max-width: 260px;
    margin-left: 7px;

    @include media-breakpoint-down(xs) {
      margin-left: 5px;
    }
  }

  &__basics-title {
    font-size: 1.25rem;
    font-weight: 500;
    align-items: flex-end;
    display: flex;
    line-height: 1;
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }

  &__basics-text {
    font-size: 0.875rem;

    &--margin-bottom {
      margin-bottom: 2rem;
    }
  }

  &__copyright {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba($white, 10%);
    font-size: 0.75rem;
  }
}
