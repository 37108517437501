.header {
  box-shadow: $box-shadow;

  &__nav {
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }

  &__logo {
    width: 165px;

    @include media-breakpoint-up(sm) {
      width: 200px;
    }

    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }

  &__org-logo {
    max-width: 50vw;
    max-height: 100px;

    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
  }

  &__nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: $c-basicare-neutral;
    transition: color 0.15s ease-in-out;
    &:hover {
      text-decoration: none;
      color: $c-basicare-orange;
    }

    &--dropdown-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__hamburger {
    border: none;
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
    position: absolute;
    right: 0;

    &:focus {
      box-shadow: 0 0 0 3px;
    }
  }

  &__side-modal {
    top: 67.88px;
  }

  &__side-modal-backdrop {
    display: none !important;
  }

  &__dialog {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;

    .modal-content {
      padding-left: 1rem;
      $side-spacing: 0px;
      max-width: 400px;
      height: 100vh;
      position: relative;
      right: $side-spacing;
      border-radius: 0;
      border: none;
      position: absolute;

      .header__org-logo {
        margin-left: 0.5rem !important;
      }

      .nav-link {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  &__bars {
    font-size: 28px;
  }

  &__banner {
    height: 60px;
    display: flex;
    align-items: center;
    color: $c-white;
    background-color: $c-basicare-orange;

    h1 {
      font-size: 1.5rem;
      font-family: $font-family-merriweather;
      font-weight: normal;
      margin-left: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 125px;

      h1 {
        font-size: 51px;
        margin-left: 3rem;
      }
    }

    @include media-breakpoint-up(xl) {
      h1 {
        font-size: 60px;
      }
    }
  }
}

.modal.fade:not(.show) .modal-dialog.header__dialog {
  transform: translate(1000px, 0);
}

#bundles-dropdown-toggle,
#bundles-dropdown-toggle-mobile {
  padding-left: 8px;
  color: $c-basicare-neutral;
  &:hover {
    color: $c-basicare-orange;
  }
  & + div {
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 1rem;
      position: relative !important;
      transform: none !important;
    }
  }
}
