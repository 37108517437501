.breadcrumbs {
  &__row {
    display: flex;
    flex-wrap: nowrap;
  }

  &__container {
    border-bottom: 1px solid rgba($c-basicare-primary, 10%);
    padding-bottom: 24px;
  }
}

.breadcrumb {
  font-size: 16px;
  font-weight: unset;
  padding: 0;
  margin: 0;

  &:first-child {
    max-width: 105px;

    @include media-breakpoint-up(xs) {
      max-width: 120px;
    }

    @include media-breakpoint-up(sm) {
      max-width: initial;
    }
  }

  &__entry {
    &--link {
      font-weight: unset;
    }
  }

  &__arrow {
    background-color: $c-basicare-primary;
    height: 3px;
    width: 60px;
    padding: 0;
    margin: 0 5px;

    @include media-breakpoint-up(xl) {
      width: 175px;
      margin: 0 20px;
    }

    @include media-breakpoint-up(md) {
      width: 140px;
      margin: 0 10px;
    }

    @include media-breakpoint-only(sm) {
      width: 140px;
      margin: 0 10px;
    }

    @include media-breakpoint-only(xs) {
      width: 75px;
    }
  }

  &__text {
    margin-top: 10px;
    font-weight: 500;
    color: $c-basicare-primary;
    display: inline;
  }
}

.breadcrumb.disabled {
  cursor: default;

  & .breadcrumb__arrow,
  & .breadcrumb-content__step {
    background-color: rgba($c-basicare-primary, 40%);
  }

  & .breadcrumb__text {
    color: rgba($c-basicare-primary, 40%);
    font-weight: 400;
  }
}

.breadcrumb-content {
  display: flex;
  align-items: center;

  &__step {
    background-color: $c-basicare-primary;
    color: $white;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    text-align: center;
    position: relative;

    &-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
